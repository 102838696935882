import Vue from "vue";
import Router from "vue-router";
import auth from "@/router/auth";

Vue.use(Router);

function checkSession(to, from, next) {
  if (Vue.prototype.$session.exists()) {
    auth.check().then((res) => {
      if (res) {
        next();
      } else {
        next("/login");
      }
    });
  } else {
    next("/login");
  }
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      component: () => import("@/pages/errors/404.vue"),
    },
    {
      path: "/login",
      name: "Login",
      component: () => import(`@/pages/Login.vue`),
      beforeEnter(to, from, next) {
        if (Vue.prototype.$session.exists()) {
          auth.check().then((res) => {
            if (res) {
              next("/home");
            } else {
              next();
            }
          });
        } else {
          next();
        }
      },
    },
    {
      path: "/reset/password/:id",
      name: "Reset",
      component: () => import(`@/pages/Reset.vue`),
    },
    {
      path: "/",
      name: "Root",
      redirect: {
        name: "Home",
      },
      component: () => import(`@/pages/Root.vue`),
      children: [
        {
          path: "/home",
          name: "Home",
          component: () => import(`@/pages/Home.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/usuarios",
          name: "Usuarios",
          component: () => import(`@/pages/User.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/empresas",
          name: "Empresas",
          component: () => import(`@/pages/Company.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/zonas",
          name: "Zonas",
          component: () => import(`@/pages/Zones.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/grupos",
          name: "Grupos",
          component: () => import(`@/pages/Groups.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/clientes",
          name: "Clientes",
          component: () => import(`@/pages/Clients.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/mis-solicitudes",
          name: "Mis solicitudes",
          component: () => import(`@/pages/Loan.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/solicitud",
          name: "Nueva solicitud",
          component: () => import(`@/pages/Request.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/solicitud/:id/:section",
          name: "Editar solicitud",
          component: () => import(`@/pages/Request.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/detalle-solicitud/:id",
          name: "Detalle solicitud",
          component: () => import(`@/pages/LoanDetails.vue`),
          props: (route) => ({ query: route.query.id }),
          beforeEnter: checkSession,
        },
        {
          path: "/detalle-cliente/:id",
          name: "Detalle cliente",
          component: () => import(`@/pages/ClientDetails.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/configuraciones-globales",
          name: "Configuraciones globales",
          alias: "Configuraciones globales",
          component: () => import(`@/pages/Globals.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/flujo-aprobacion/:id",
          name: "Flujo de aprobación",
          component: () => import(`@/pages/aproveFlow.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/pagos-pendientes",
          name: "Autorizar pagos",
          component: () => import(`@/pages/PendingPayments.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/convenios",
          name: "Convenios",
          component: () => import(`@/pages/Agreement.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/pagos/:id",
          name: "Pagos",
          component: () => import(`@/pages/Pays.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/reporte/personal",
          name: "Reporte de personal",
          component: () => import(`@/pages/PersonalReport.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/reporte/entrega",
          name: "Reporte de entrega",
          component: () => import(`@/pages/DeliveryReport.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/reporte/pagos-semanal",
          name: "Reporte de pagos semanal",
          component: () => import(`@/pages/WeeklyPaymentReport.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/reporte/domiciliario",
          name: "Reporte Domiciliario",
          component: () => import(`@/pages/AddressReport.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/reporte/preanalisis",
          name: "Preanálisis",
          component: () => import(`@/pages/PreanalysisReport.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/reporte/hoja1",
          name: "Hoja 1",
          component: () => import(`@/pages/SheetOneReport.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/historial/prestamos-activos",
          name: "Préstamos activos",
          component: () => import(`@/pages/LoanRequestHistory.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/historial/morosos",
          name: "Morosos",
          component: () => import(`@/pages/Defaulter.vue`),
          beforeEnter: checkSession,
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  next();
});

router.afterEach((to, from) => { });

export default router;
